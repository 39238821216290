import { SVLRouteValue } from '@domain/shared/svl-route-value';
import { CoreRouteNames } from '../core.routenames';

interface PlatformContractsRouteNamesType {
    list: SVLRouteValue;
    create: SVLRouteValue;
    detail: SVLRouteValue;
    update: SVLRouteValue;
}

export const PlatformContractsRouteNames: PlatformContractsRouteNamesType = {
    list: { local: '', global: CoreRouteNames.platformContracts.global },
    create: { local: 'create', global: CoreRouteNames.platformContracts.global + '/create' },
    detail: {
        local: 'detail/:id',
        global: CoreRouteNames.platformContracts.global + '/detail',
    },
    update: {
        local: 'update/:id',
        global: CoreRouteNames.platformContracts.global + '/update',
    },
};
